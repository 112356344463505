.vai-application {
  height: auto;
}

.vai-input-field {
  height: 1.625rem; // force IE11 to matching input field height
  font-family: "Source Sans Pro", sans-serif;
}

.vai-button {
  font-family: "Source Sans Pro", sans-serif;
}

.vai-tooltip__wrapper--error {
  z-index: 100;
}

.vai-tooltip__content--error {
  min-height: 32px;
  padding: 0.35rem;
  width: 200px;
}

.vai-form-item {
  margin-bottom: 0.7rem !important;
}

.vai-heading {
  margin-top: -5px;
  margin-bottom: -2px;
}
