.vai-header {
  display: flex;
}

.vai-header__action {
  float: left;
  padding-right: 10px;
  padding-left: 15px;
}

.header .vai-modal__box--l {
  width: 550px;
}

.settings .vai-heading {
  margin-top: 0px;
  margin-bottom: 24px;
  text-align: center;
}

.settings .ant-select-dropdown-menu {
  max-height: 120px;
  margin-top: 5px;
}

.settings .vai-form {
  max-width: 510px;
}

.settings .separator {
  margin-bottom: 11px;
}

.vai-select .ant-select {
  font:
    400 14px "Source Sans Pro",
    sans-serif;
}

// avoid settings modal from sliding to bottom of screen when preview has data
.appPage .vai-modal {
  height: 700px;
}

@media only screen and (max-width: 768px) {
  .vai-header-time {
    display: none;
  }
  .vai-header-item__title {
    display: none;
  }
}

// Override so on small screen Vaisala logo wont go away
.vai-header-brand {
  display: initial;
  z-index: auto;
}
