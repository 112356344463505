@import "src/variables";

.latLonHeaderPanel {
  display: flex;
  margin-bottom: -5px;
}

.latLonHeaderPanel .vai-input-field--s {
  width: 106px;
}

.latLonLabel {
  font-size: $vai-font-size-s;
}

.latLonPanel {
  display: flex;
}

.longitudePanel {
  padding-left: $vai-spacing-s;
}
