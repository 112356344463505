.dateTimePanel {
  display: flex;
}

.hourPanel {
  padding-left: 6px;
  padding-right: 2px;
}

.minutePanel {
  padding-left: 3px;
}

.hourPanel .vai-input-field__input,
.minutePanel .vai-input-field__input {
  width: 43px;
}

.hourPanel .vai-input-field-container,
.minutePanel .vai-input-field-container {
  line-height: inherit;
}

.hourMinuteSeparator {
  line-height: 24px;
}

.vai-input-field__wrapper--m {
  width: auto !important;
}

.timeZoneSelector .vai-select .ant-select {
  width: 104px;
}

.timeZoneSelector .ant-select-dropdown-menu {
  max-height: 170px;
  margin-top: 5px;
}
