@import "src/variables";

.lightningLegend {
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 10px;
}

.colorLegend {
  width: 350px;

  display: grid;
  grid-template-columns: auto auto auto;
}

.colorScale {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
  background-image: linear-gradient(to left, #050055, #3cf0fa);
  width: 100%;
  height: 0.5rem;
  margin: auto;
}

.timeLegendTimes {
  grid-row: 2;
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
}

.timeLegendStart {
  text-align: left;
  padding-right: 4px;
}

.timeLegendEnd {
  text-align: right;
  padding-left: 4px;
}

.iconLegend {
  justify-content: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}
