@import "src/variables";

.mapEditorPanel {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  float: right;
  padding: 3px;
  width: 141px;
}

.mapEditorHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.headerText {
  margin-top: 14px;
  font-size: 14px;
  margin-left: 17px;
}

.circleHeaderText {
  margin-top: 14px;
  font-size: 14px;
  margin-left: 22px;
}

.closeIcon {
  font-size: 1.5rem;
  margin-right: -2px;
  cursor: pointer;
}

.drawRectangleImage {
  display: flex;
  justify-content: center;
  padding-left: 5px;
  padding-top: 3px;
}

.drawCircleImage {
  display: flex;
  justify-content: center;
  padding-left: 5px;
  padding-top: 3px;
}

.mouseClickText {
  justify-content: center;
  display: flex;
  font-size: 13px;
  margin-top: -13px;
  padding-bottom: 5px;
  color: $vai-color-grey-medium;
}

.circleMouseClickText {
  justify-content: center;
  display: flex;
  font-size: 13px;
  margin-right: 30px;
  padding-bottom: 5px;
  color: $vai-color-grey-medium;
}
