@import "../../variables.scss";

.topPanel {
  display: flex;
  justify-content: space-between;
}

.mapPanel {
  height: auto;
  min-height: 700px;
  flex-grow: 4;
  padding: 20px;
}

.errorBorder {
  border: 1px solid $vai-color-red;
}

.alertBorder {
  border: 1px solid $vai-color-aqua-vaisala;
}

// switch to portrait layout
@media screen and (max-width: 68em) {
  .topPanel {
    flex-direction: column;
  }

  .topPanel .formPanel {
    min-width: initial;
    align-self: flex-start;
  }

  .mapPanel {
    width: 100%;
  }
}
